import React from "react";
import Layout from "../components/layouts/layout.js";
import { Helmet } from "react-helmet";
import { HEADER_LEVEL } from "../utils/commonConst.js";
import HeaderText from "../components/common/HeaderText.js";

export default function Home() {
  return (
    <>
      <Helmet title="404 Not Found" />
      <Layout>
        <HeaderText level={HEADER_LEVEL.LEVEL_1} className="text-center">
          404 Not Found
        </HeaderText>
      </Layout>
    </>
  );
}
